

import React, { useEffect, useState } from "react";
import Header from "../custom/topnavBar";
import { Grid, Card, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { gettaData } from "../loginData/loginData";
import { getTeacherData } from "../loginData/loginData";
import { getStudentData } from "../loginData/loginData";
import { sideBarDataAction } from "../redux/Actions/GetActions/getSideBarDataAction"
import BookIcon from '@mui/icons-material/Book';
import { GetLeaderBoardAction } from "../redux/Actions/GetActions/getLeaderBoardAction";
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import Attendace from "../components/StudentDashboardComponent/AttendaceOfCourses"
import Calendar from "../components/StudentDashboardComponent/Calendar";
import Aggregate from "../components/StudentDashboardComponent/AggregateOfStudent/index"
import Leaderboard from "../components/Leaderboard";
import GroupMembers from "../components/StudentDashboardComponent/GroupMembers";



const CourseDashboard = ({ components, componentID, id, componentName, classItem, roleName, roleID, userroleID }) => {
    const { loginData } = useSelector((state) => state?.LOGINREDUCER);
    const taData = gettaData(loginData);
    let UserDetailsData = []
    const [selctedLeaderboard, setSelctedLeaderboard] = useState([])
    console.log("selctedLeaderboard", selctedLeaderboard)
    const studentData = getStudentData(loginData);
    const roleNameSelected = loginData?.payload?.roleData[0]?.RoleName
    const dispatch = useDispatch();
    const teacherData = getTeacherData(loginData);
    const roleData = loginData && loginData.payload ? loginData?.payload?.RoleData : [];
    const [getLeaderboard, setGetLeaderboard] = useState([])
    const [getLeaderboardResult, setGetLeaderboardResult] = useState([])
    const [openLeaderboard, setOpenLeaderboard] = useState(false)
    const [GroupName, setGroupName] = useState(studentData.find((item) => item.CourseId === id).GroupName);
    console.log("GroupName", studentData)
    useEffect(() => {
        const fetchLeaderboardData = async () => {

            dispatch(GetLeaderBoardAction(
                id,
                "0",
                (response) => {
                    console.log("response", response);
                    setGetLeaderboard(response?.payload || []);
                },
                (error) => {

                    console.log("error attc", error);
                }
            )
            )
        };



        if (id) { fetchLeaderboardData() }
    }, [id])
    useEffect(() => {
        console.log("getLeaderboard:", getLeaderboard); // Log the getLeaderboard state
        if (getLeaderboard.length !== 0) {
            const subcomponentIds = getLeaderboard
                .flatMap(leaderboard => {
                    console.log("leaderboard:", leaderboard); // Log each leaderboard object
                    return Array.isArray(leaderboard.SubComponentData)
                        ? leaderboard.SubComponentData.map(item => item.SubComponentId)
                        : []; // Return an empty array if SubComponentData is not an array
                });

            console.log("subcomponentIds:", subcomponentIds); // Log the final subcomponentIds array
        }
    }, [getLeaderboard]);


    return (
        <>
            <Grid
                container
                sx={{
                    marginLeft: '20px',
                    paddingRight: '50px',
                    marginTop: "50px"
                }}
            >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    <Grid item xs={12} md={12} lg={12} xl={8}>

                        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                            <Grid item xs={12} md={6} lg={6}>

                                <Attendace StudentData={studentData} CourseId={id} />

                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>

                                <Aggregate CourseId={id} />
                            </Grid>
                            {GroupName&&(
                                <Grid item xs={12} md={6} lg={6}>

                                <GroupMembers CourseId={id} />
                            </Grid>)}
                        </Grid>

                        {getLeaderboard.length > 0 && (
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    marginTop: '20px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: "100%"
                                }}
                            >
                                {getLeaderboard.map((leaderboard, index) => (
                                    <Grid item xs={12} md={6} lg={4} key={index}>
                                        <Card
                                            sx={{
                                                padding: '16px',
                                                marginBottom: '20px',
                                                backgroundImage: `
                                                    linear-gradient(135deg, 
                                                        #dadcf5, 
                                                        #C8CCF9, 
                                                        #9EA5F4, 
                                                        #757FEF
                                                    )
                                                `,
                                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                                borderRadius: '12px',
                                                textAlign: 'center',
                                                transition: 'transform 0.2s',
                                                '&:hover': {
                                                    transform: 'scale(1.05)',
                                                }
                                            }}

                                            onClick={() => {
                                                setSelctedLeaderboard(leaderboard)
                                                setOpenLeaderboard(true)
                                            }}


                                        >
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '12px',
                                                    color: '#4e545c',
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {leaderboard.LeaderboardName}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        )}

                        <Leaderboard open={openLeaderboard} handleClose={() => setOpenLeaderboard(false)} leaderboard={selctedLeaderboard} />
                    </Grid>

                </Grid>



            </Grid>

        </>
    );
};

export default CourseDashboard;


