import { GET_SUBCOMPONENT_BY_COURSE  } from "../ActionType/Types";

import Constants from "../../Constant"

export function GetSubComponentByCourse(token,CourseId ,  onSuccess ,onFailure){
    return{
        type:GET_SUBCOMPONENT_BY_COURSE,
        payload:{
            apiUrl:`${Constants.getSubcomponentByCourseId}/${CourseId}`,
            header:"application/json",
            metaData:true,
            requestType:'GET',
            reduxActionType:"",
            onSuccess:onSuccess,
            onFailure:onFailure,
            token:token,

        }
    }
}