import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import DefaultPage from "./defaultPage";
import Announcement from "./announcement";
import Assignments from "./assignment";
import AttendancePage from "./attendancePage";
import StudentProgress from "./StudentProgress";
import CourseDashboard from "../views/CourseDashboard";
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookComponent from "./BookComponent";
import Aggregate from "./Aggregate";
import { useDispatch, useSelector } from "react-redux";
import { GetclasscomponentAction } from "../redux/Actions/GetActions/getclasscomponentAction";
import { isLoadingAction } from "../redux/Actions/PostActions/isLoadingAction";
import { sideBarDataAction, sideBarActiveTabAction } from "../redux/Actions/GetActions/getSideBarDataAction";
import { getRoleData, getTeacherData, gettaData, getAccessToken, getStudentData } from "../loginData/loginData";
import { useNavigate, useParams } from "react-router-dom";
import AssignmentIcon from '@mui/icons-material/Assignment';
import QuizIcon from '@mui/icons-material/Quiz';
import EventIcon from '@mui/icons-material/Event';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ClassIcon from '@mui/icons-material/Class';
import WorkIcon from '@mui/icons-material/Work';
import BookIcon from '@mui/icons-material/Book';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import SchoolIcon from '@mui/icons-material/School';
import GradedComponent from "../components/GradedComponents/index"
import Lecture from "./Lecture/LectureComponent"
import LectureWiseAttendance from "./details/LectureWiseAttendance";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Leaderboard from "./Leaderboard";

const iconMapping = {
  'Assignment': <AssignmentIcon sx={{ color: '#FF6347' }} />,  // Tomato Red
  'Quiz': <QuizIcon sx={{ color: '#4682B4' }} />,  // Steel Blue
  'Final': <EventIcon sx={{ color: '#32CD32' }} />,  // Lime Green
  'Mid': <EventIcon sx={{ color: '#FFD700' }} />,  // Gold
  'Announcement': <AnnouncementIcon sx={{ color: '#FF4500' }} />,  // Orange Red
  'Lecture': <ClassIcon sx={{ color: '#6A5ACD' }} />,  // Slate Blue
  'Project': <WorkIcon sx={{ color: '#FF69B4' }} />,  // Hot Pink
  'Lab': <WorkIcon sx={{ color: '#7B68EE' }} />,  // Medium Slate Blue
  'Book': <BookIcon sx={{ color: '#20B2AA' }} />,  // Light Sea Green
  'Progress': <BarChartIcon sx={{ color: '#FF8C00' }} />,  // Dark Orange
  'Aggregate': <PieChartIcon sx={{ color: '#8A2BE2' }} />,  // Blue Violet
  'CourseHome': <SchoolIcon sx={{ color: '#40E0D0' }} />,  // Turquoise
  "CourseDashboard": <DashboardIcon sx={{ color: '#FF6347' }} />,
};


function ClassroomTabs({ classItem, courseName, isPublic = false }) {
  const { id, tab } = useParams();
  const [components, setComponents] = useState([]);
  const { loginData } = useSelector((state) => state?.LOGINREDUCER);
  const navigate = useNavigate
  const ActiveTab = useSelector((state) => state?.SIDERBARDATA.ActiveTab);
  const [value, setValue] = useState(Number(tab) || 0);
  const token = getAccessToken(loginData);
  const dispatch = useDispatch();
  const componentsCache = useRef({});
  const roleData = getRoleData(loginData);
  const { RoleId, RoleName, UserroleId } = roleData || {};
  const studentData = getStudentData(loginData);
  const Teacherdata = getTeacherData(loginData)
  const TaData = gettaData(loginData)

  const getDataSource = () => {
    if (TaData && TaData.length > 0) {
      return TaData;
    } else if (studentData && studentData.length > 0) {
      return studentData;
    } else if (Teacherdata && Teacherdata.length > 0) {
      return Teacherdata;
    }
    return [studentData, TaData, Teacherdata];
  };
  const CourseId = getDataSource()?.find((enrollment) => enrollment.CourseName === courseName)

  useEffect(() => {
    if (CourseId) {
      dispatch(isLoadingAction(true));
      dispatch(GetclasscomponentAction(token, CourseId.CourseId, isPublic,
        (response) => {
          const filteredComponents = roleData.RoleName === "Student" ?
            response?.payload.filter(c => c.ComponentName !== "Aggregate") || [] :
            response?.payload || [];
          setComponents(filteredComponents);
          componentsCache.current[id] = response?.payload || [];
          dispatch(isLoadingAction(false));

          let componentsArray = filteredComponents.map(item => {
            let component = {
              title: item.ComponentName,
              ComponentId: item.ComponentID,
              icon: iconMapping[item.ComponentName],
            };

            if (item.ComponentName === "Lecture") {
              component.subNav = [
                {
                  title: "Attendance"
                }
              ];
            }

            return component;
          });
          if (RoleName == "Student")
          {componentsArray = [
            
            {
              title: "CourseDashboard",
              icon: iconMapping["CourseDashboard"],
            },
            ...componentsArray,

          ];}
          if (RoleName !== "Student") {
            componentsArray = [
            
              {
                title: "CourseHome",
                path: `/classroomHome/${courseName}/${CourseId.CourseId}`,
                icon: iconMapping["CourseHome"],
              },
              ...componentsArray,

            ];
          }
          dispatch(sideBarDataAction(componentsArray));
          if (!ActiveTab.ComponentId) {
            // if (RoleName === "Student" || RoleName === "TA" && !(ActiveTab.ComponentName in iconMapping)) {
            //   dispatch(sideBarActiveTabAction("Announcement", componentsArray.find(item => item.title === "Announcement")?.ComponentId));
            // }


          }

        },
        (error) => {
          console.log("Error fetching components:", error);
          dispatch(isLoadingAction(false));
        }
      ));
    }
  }, [CourseId]);

  useEffect(() => {
    // Handle tab change
    if (ActiveTab) {
      if (ActiveTab.ComponentName == "Attendance") {
        const index = components.findIndex(c => c.ComponentName === "Lecture");
        setValue(index);
        return
      }
      const index = components.findIndex(c => c.ComponentName === ActiveTab.ComponentName);
      setValue(index);
    }
  }, [ActiveTab, components]);
  const renderTabContent = () => {
    const { ComponentId, ComponentName } = ActiveTab || {};
    if (ComponentName != "CourseHome" && !ComponentName) {
      const selectedComponent = components[value];
      if (!selectedComponent) {
        return <CircularProgress />;
      }
    }
      if (ComponentName == "Attendance") {
        return <LectureWiseAttendance CourseId={CourseId} />
      }
    switch (ComponentName) {
      case 'Assignment':
      case "Lab":
      case 'Quiz':
      case 'Final':
      case 'Mid':
      case 'Project':
      case "Announcement":
        return <GradedComponent key={ComponentId} CourseId={CourseId} courseName={courseName} componentID={ComponentId} componentName={ComponentName} classItem={classItem} roleName={RoleName} roleID={RoleId} userroleID={UserroleId} isPublic={isPublic} components={components} />;
      case 'Lecture':
        return <Lecture componentID={ComponentId} CourseId={CourseId} courseName={courseName} componentName={ComponentName} classItem={classItem} roleName={RoleName} roleID={RoleId} userroleID={UserroleId} isPublic={isPublic} components={components}/>
      case 'Book':
        return <BookComponent componentID={ComponentId} componentName={ComponentName} isPublic={isPublic} />;
      case 'Progress':
        return <StudentProgress id={CourseId.CourseId} />;
      case 'Aggregate':
        return <Aggregate id={CourseId.CourseId} />;
      case "CourseHome":
        return <DefaultPage componentID={ComponentId} id={CourseId.CourseId} componentName={ComponentName} classItem={classItem} roleName={RoleName} roleID={RoleId} userroleID={UserroleId} />;
      case "Attendance":
        return <LectureWiseAttendance CourseId={CourseId} />
      case "CourseDashboard":
        return <CourseDashboard components={components} componentID={ComponentId} id={CourseId.CourseId} componentName={ComponentName} classItem={classItem} roleName={RoleName} roleID={RoleId} userroleID={UserroleId} />;
      case "Leaderboard":
        return <Leaderboard />
        default:
        return <DefaultPage componentID={ComponentId} id={CourseId.CourseId} componentName={ComponentName} classItem={classItem} roleName={RoleName} roleID={RoleId} userroleID={UserroleId} />;;
    }
  };
  return (
    <>
      {renderTabContent()}
    </>
  );
}

export default ClassroomTabs;
