import { GET_LEADERBOARD} from "../ActionType/Types";
import Constants from "../../Constant"
export function GetLeaderBoardAction(courseId,teacherFlag,onSuccess ,onFailure){
    return{
        type:GET_LEADERBOARD,
        payload:{
            metaData: true,
            header:"/application/json",
            apiUrl:Constants.getLeaderBoard+"/"+courseId+"/"+teacherFlag,
            requestType:'GET',
            reduxActionType: GET_LEADERBOARD,
            onSuccess:onSuccess,
            onFailure:onFailure,
        }
    }
}