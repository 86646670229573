// auth/login
export const LOGIN ="LOGIN";
export const ADMIN_LOGIN="ADMIN_LOGIN";
export const SET_TOKEN_EXPIRED="SET_TOKEN_EXPIRED"

// auth/logout
export const LOGOUT="LOGOUT";
export const ADMIN_LOGOUT="ADMIN_LOGOUT";
// get class component
export const GETCLASSCOMPONENT='GETCLASSCOMPONENT';
export const ADMIN_GET_CLASSCOMPONENT ='ADMIN_GET_CLASSCOMPONENT';

// add class component
export const POSTCLASSCOMPONENT='POSTCLASSCOMPONENT';
export const ADMIN_POST_CLASSCOMPONENT='ADMIN_POST_CLASSCOMPONENT';

//  delete class component
export const DELETECLASSCOMPONENT='DELETECLASSCOMPONENT';
export const ADMIN_DELETE_CLASS_COMPONENT='ADMIN_DELETE_CLASS_COMPONENT';
// get sub components
export const GETSUBCOMPONENT='GETSUBCOMPONENT';
export const ADMIN_GET_SUBCOMPONENT='ADMIN_GET_SUBCOMPONENT';

// add sub components
export const POSTSUBCOMPONENT='POSTSUBCOMPONENT';
export const ADMIN_POST_SUBCOMPONENT='ADMIN_POST_SUBCOMPONENT';
export const ADD_TEMPLATE_SUBCOMPONENT="ADD_TEMPLATE_SUBCOMPONENT"
export const GET_TEMPLATE_SUBCOMPONENT="GET_TEMPLATE_SUBCOMPONENT"
export const REDUX_GET_TEMPLATE_SUBCOMPONENT="REDUX_GET_TEMPLATE_SUBCOMPONENT"
export const SUPPORT_TASK="SUPPORT_TASK"




// add chats
export const POSTCHAT ='POSTCHAT';
export const ADMIN_POST_CHATS='ADMIN_POST_CHATS';

// get chats
export const GETCHATS ='GETCHATS' ;
export const ADMIN_GET_CHATS='ADMIN_GET_CHATS';

// get student enrolled in course
export const GETSTUDENTENRTOLLEDINCOURSE ='GETSTUDENTENRTOLLEDINCOURSE';
export const ADMIN_GET_STUDENT_ENROLLED_IN_COURSE='ADMIN_GET_STUDENT_ENROLLED_IN_COURSE';

// lecture attendace
export const POSTLECTUREATTENDANCE='POSTLECTUREATTENDANCE';
export const ADMIN_POST_LECTURE_ATTENDANCE='ADMIN_POST_LECTURE_ATTENDANCE';

// course calender
export const GETCOURSECALENDER='GETCOURSECALENDER';
export const ADMIN_GET_COURSE_CALENDER='ADMIN_GET_COURSE_CALENDER';

// course student enrolleded
export const GETCOURSESTUDENTENROLLED ='GETCOURSESTUDENTENROLLED';
export const ADMIN_GET_COURSE_STUDENT_ENROLLED ='ADMIN_GET_COURSE_STUDENT_ENROLLED';

// student submission
export const POSTSTUDENTSUBMISSION='POSTSTUDENTSUBMISSION';
export const ADMIN_POST_STUDENT_SUBMISSION= 'ADMIN_POST_STUDENT_SUBMISSION';
export const GETSTUDENTSUBMISSION='GETSTUDENTSUBMISSION';
export const ADMIN_GET_STUDENT_SUBMISSION='ADMIN_GET_STUDENT_SUBMISSION';

// attachments
export const POSTATTACHMENTS='POSTATTACHMENTS';
export const ADMIN_POST_ATTACHMENTS='ADMIN_POST_ATTACHMENTS';

// sub component marks
export const POSTSUBCOMPONENTMARKS='POSTSUBCOMPONENTMARKS';
export const ADMIN_POST_SUBCOMPONENT_MARKS='ADMIN_POST_SUBCOMPONENT_MARKS';

// get sub attach
export const GETSUBATTACHMENTS='GETSUBATTACHMENTS';
export const ADMIN_GET_SUB_ATTACHMENTS='ADMIN_GET_SUB_ATTACHMENTS';


// get sutdent submission attachments

export const GETSTUDENTSUBATTACHMENTS='GETSTUDENTSUBATTACHMENTS';
export const ADMIN_GET_STUDENT_SUBS_ATTACHMENTS='ADMIN_GET_STUDENT_SUBS_ATTACHMENTS';


// Delete student submission 
export const DELETESTUENTSUBMISSION='DELETESTUENTSUBMISSION';
export const ADMIN_DELETE_STUDENT_SUBMISSION='ADMIN_DELETE_STUDENT_SUBMISSION';

// Get student MARKS

export const GETSTUDENTMARKS='GETSTUDENTMARKS';
export const ADMIN_GET_STUDENT_MARKS='ADMIN_GET_STUDENT_MARKS';

// UPDATE SUBCOMPONENT MARKS
export const UPDATESTUDENTMARKS='UPDATESTUDENTMARKS';
export const ADMIN_UPDATE_STUDENT_MARKS='ADMIN_UPDATE_STUDENT_MARKS';


// Books
export const GETBOOKS='GETBOOKS';
export const ADMIN_GET_BOOKS='ADMIN_GET_BOOKS';

export const DELETEBOOKS='DELETEBOOKS';
export const ADMIN_DELETE_BOOKS='ADMIN_DELETE_BOOKS';

export const DELETESUBCOMPONENT='DELETESUBCOMPONENT';
export const ADMIN_DELETE_SUBCOMPONENT='ADMIN_DELETE_SUBCOMPONENT';

export const ADDBOOK='ADDBOOK';
export const ADMIN_ADD_BOOKS='ADMIN_ADD_BOOKS';

export const UPDATEBOOKS='UPDATEBOOKS';
export const ADMIN_UPDATE_BOOKS='ADMIN_UPDATE_BOOKS';


// Student Enrollments
export const ADDSTUDENTENROLLEMENTS='ADDSTUDENTENROLLEMENTS';
export const ADMIN_ADD_STUDENT_ENROLLEMENTS='ADMIN_ADD_STUDENT_ENROLLEMENTS';

export const GETSTUDENTENROLLEMENTFORCOURSE='GETSTUDENTENROLLEMENTFORCOURSE';
export const ADMIN_GET_STUDEN_ENROLLEMENT_FOR_COURSE='ADMIN_GET_STUDEN_ENROLLEMENT_FOR_COURSE';



// Update class component

export const ADMINUPDATECLASSCOMPONENT='ADMINUPDATECLASSCOMPONENT';
export const ADMIN_UPDATE_CLASS_COMPONENT='ADMIN_UPDATE_CLASS_COMPONENT';


// Add Preferred Time Slot

export const ADMINADDPREFERREDTIMESLOT='ADMINADDPREFERREDTIMESLOT';
export const ADMIN_ADD_PREFERRED_TIME_SLOT='ADMIN_ADD_PREFERRED_TIME_SLOT';


// Time SLot
export const ADMINGETTIMESLOT='ADMINGETTIMESLOT';
export const ADMIN_GET_TIME_SLOT='ADMIN_GET_TIME_SLOT';



// Update subcomponent
export const ADMINUPDATESUBCOMPONENT='ADMINUPDATESUBCOMPONENT';
export const ADMIN_UPDATE_SUB_COMPONENT='ADMIN_UPDATE_SUB_COMPONENT';


// Student Attendance
export const ADMINGETSTUDENTATTENDANCE='ADMINGETSTUDENTATTENDANCE';
export const ADMIN_GET_STUDENT_ATTENDANCE='ADMIN_GET_STUDENT_ATTENDANCE';


// Public Page
export const ADMINGETPUBLICPAGE='ADMINGETPUBLICPAGE';
export const ADMIN_GET_PUBLIC_PAGE='ADMIN_GET_PUBLIC_PAGE';


// Otp Auth
export const ADMIN_LOGIN_STEP_2 ='ADMIN_LOGIN_STEP_2';

// Clos

export const GETCLOS ='GETCLOS';
export const GET_CLOS='GET_CLOS';


// Student Perfromance
export const GETINDIVIDUALSTUDENTPERFORMANCE='GETINDIVIDUALSTUDENTPERFORMANCE';
export const GET_INDIVIDUAL_STUDENT_PERFORMANCE='GET_INDIVIDUAL_STUDENT_PERFORMANCE';

// Pending Courses
export const GETPENDINGCOURSES='GETPENDINGCOURSES';
export const GET_PENDING_COURSES='GET_PENDING_COURSES';

// whole class performance
export const GETWHOLECLASSPERFORMANCE='GETWHOLECLASSPERFORMANCE';
export const GET_WHOLE_CLASS_PERFORMANCE='GET_WHOLE_CLASS_PERFORMANCE';


// Gpt Question Rephrase
export const QUESTIONREPHRASE='QUESTIONREPHRASE';
export const QUESTION_REPHRASE='QUESTION_REPHRASE';


// Get all student couurse
export const GETSTUDENTBYCOURSE='GETSTUDENTBYCOURSE';
export const GET_STUDENT_BY_COURSE ='GET_STUDENT_BY_COURSE';



//  Get Class aggregate

export const GETCLASSAGGREGATE='GETCLASSAGGREGATE';
export const GET_CLASS_AGGREGATE='GET_CLASS_AGGREGATE';


export const GET_PERSONAL_SUBDOMAIN="GET_PERSONAL_SUBDOMAIN"



// Add Question 
export  const ADDQUESTIONS="ADDQUESTIONS"
export const ADD_QUESTIONS="ADD_QUESTIONS";
export const UPDATE_QUESTIONS="UPDATE_QUESTIONS";




// Get Question By Sub comonent
export const GETQUESTIONBYSUBCOMPONENTID='GETQUESTIONBYSUBCOMPONENTID';
export const GET_QUESTION_BY_SUBCOMPONENT_ID='GET_QUESTION_BY_SUBCOMPONENT_ID';



// Add stujdent question answe

export const ADDSTUDENTANS='ADDSTUDENTANS';
export const ADD_STUDENT_ANS='ADD_STUDENT_ANS';

export const UPDATEQUESTIONMARKSANS='UPDATEQUESTIONMARKSANS';
export const ADMIN_UPDATE_QUESTION_MARKS_ANS='ADMIN_UPDATE_QUESTION_MARKS_ANS';

// UPDATEQUESTIONMARKSANS

// CLO Graph
export const GETCLOGRAPH="GETCLOGRAPH";
export const GET_CLO_GRAPH ="GETCLOGRAPH";

export const IS_LOADING="IS_LOADING";
export const ACTIVITY_OUTCOME="ACTIVITY_OUTCOME"
export const ACTIVITIES="ACTIVITIES"
export const TOGGLE_SIDEBAR="TOGGLE_SIDEBAR"
export const SIDEBAR_DATA="SIDEBAR_DATA"
export const ACTIVE_TAB="ACTIVE_TAB"

export const GET_CALENDAR_EVENTS="GET_CALENDAR_EVENTS"
export const REDUX_CALENDAR_EVENTS="REDUX_CALENDAR_EVENTS"

export const GET_MAX_MIN_AGGREGATE="GET_MAX_MIN_AGGREGATE"
export const REDUX_MAX_MIN_AGGREGATE="REDUX_MAX_MIN_AGGREGATE"


export const GET_SUBCOMPONENT_GRADESHEET="GET_SUBCOMPONENT_GRADESHEET"
export const GET_GROUP_MEMBERS="GET_GROUP_MEMBERS"


export const ADD_LEADERBOARD="ADD_LEADERBOARD"
export const GET_LEADERBOARD="GET_LEADERBOARD"
export const  GET_SUBCOMPONENT_BY_COURSE="GET_SUBCOMPONENT_BY_COURSE"

export const GET_LEADERBOARD_RESULT="GET_LEADERBOARD_RESULT"

export const UPDATE_COURSE_LEADERBOARD="UPDATE_COURSE_LEADERBOARD"
export const ADDCHAT="ADDCHAT"
export const GETCHAT="GETCHAT"
export const UPDATE_GROUP_NAME="UPDATE_GROUP_NAME"
