import { Assignment } from "@mui/icons-material";

export const routesName = {
    loginPage:"/auth/login",
    dashboardView:"/home-page",
    classroomHome:"/classroomHome",
    publicclassroom:"/public-classroom",
    assignmentDetails: "/classroom",
    setting: "/classroom/setting",
    publicpage:"/public-page",
    archived:'/classroom/archived',
    forgetpassword:"/auth/forgot-password",
    otp:"/auth/otp",
    // chnagePw:"/auth/forget-password/otp/change-pw",
    pendingClass:"/classroom/pending-classes",
    ClassComponent:"/classroom/class-components",
    Assignment:"/classroom/assignment",
    Announcement:"/classroom/announcement",
    Lecture:"/classroom/lecture",
    Project:"/classroom/project",
    Book:"/classroom/book",
    Progress:"/classroom/progress",
    Aggregate:"/classroom/aggregate", 
    StudentDashboard:"/classroom/Student-Dashboard",
}