import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { GetStudentattendanceAction } from "../../redux/Actions/GetActions/getstudentattendanceAction";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { isLoadingAction } from "../../redux/Actions/PostActions/isLoadingAction";
import { getStudentData } from "../../loginData/loginData";

const LectureWiseAttendance = ({ classItem, componentID, EnrollmentId,CourseId }) => {
 
  const { loginData } = useSelector((state) => state?.LOGINREDUCER);
  const [attendance, setAttendance] = useState(false);
  const { token } = useSelector((state) => state.LOGINREDUCER.loginData);
  const dispatch = useDispatch();
  const { studentattendanceData } = useSelector((state) => state?.STUDENTATTENDANCE);
  const studenData = getStudentData(loginData);

  const mapArray = studenData;
  const matchingEnrollment =
    mapArray && mapArray?.find((enrollment) => enrollment.CourseId == CourseId.CourseId);
  const enrollmentId = matchingEnrollment
    ? matchingEnrollment.EnrollementId
    : null;
  useEffect(() => {
    handlegetAttendance();
  }, [token]);
  const handlegetAttendance = () => {
    dispatch(isLoadingAction(true));
    dispatch(
      GetStudentattendanceAction(
        token,
        0,
        CourseId.CourseId,
        (response) => {
          dispatch(isLoadingAction(false));
        },
        (error) => {
          dispatch(isLoadingAction(false));
        },
        1
      )
    );
  };
  const uniqueStudentData = {};
  const lectureNumbers = new Set();
  
  studentattendanceData?.payload?.forEach((data) => {
    if (!uniqueStudentData[data.EnrollementId]) {
      uniqueStudentData[data.EnrollementId] = {
        ...data,
        lectures: {},
        presentCount: data.isPresent ? 1 : 0,
        totalClasses: 1,
      };
    } else {
      uniqueStudentData[data.EnrollementId].presentCount += data.isPresent ? 1 : 0;
      uniqueStudentData[data.EnrollementId].totalClasses += 1;
    }
    uniqueStudentData[data.EnrollementId].lectures[data.LectureNumber] = data.isPresent ? "Present" : "Absent";
    lectureNumbers.add(data.LectureNumber);
  });



  // Convert map values to array and calculate percentage
  const rows =  enrollmentId?Object.values(uniqueStudentData).map((student) => ({
    ...student,
    Percentage: ((student.presentCount / student.totalClasses) * 100).toFixed(2) + "%",
  })).filter((student) => student.EnrollementId ==   enrollmentId):Object.values(uniqueStudentData).map((student) => ({
    ...student,
    Percentage: ((student.presentCount / student.totalClasses) * 100).toFixed(2) + "%",
    }));

  // Define dynamic columns for lectures
  const lectureColumns = Array.from(lectureNumbers).map((lectureNumber) => ({
    field: `Lecture_${lectureNumber}`,
    headerName: `Lecture ${lectureNumber}`,
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => params.row.lectures[lectureNumber] || "Absent",
  }));
  const columns = [
    {
      field: "RegistrationNumber",
      headerName: "Registration Number",
      minWidth: 230,
      flex: 1,
    },
    {
      field: "Student_Name",
      flex: 1,
      headerName: "Student Name",
      minWidth: 200,
    },
    {
      field: "Percentage",
      headerName: "Percentage",
      minWidth: 150,
      flex: 1,
    },
    ...lectureColumns,
  ];

  return (
    <>
      <Box
        height={'auto'}
        sx={{
          overflowX: "auto",
          margin: "30px",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            fontSize: "14px",
          },
          "& .MuiDataGrid-columnHeaders": {
            background: "#e5e7eb",
            borderBottom: "none",
            lineHeight: "20px",
            fontWeight: 400,
            fontSize: "15px",
            color: "#000",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          components={{ Toolbar: GridToolbar }}
          density="compact"
          // checkboxSelection
          getRowId={(row) => row?.EnrollementId}
          noRowsOverlay={<div style={{ textAlign: 'center', padding: '20px', fontSize: '16px', color: 'gray' }}>No rows to display</div>}
        />
      </Box>
    </>
  );
};

export default LectureWiseAttendance;
