import React,{useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route ,useNavigate} from 'react-router-dom';
import { Box, useMediaQuery } from "@mui/material";
import { Provider, useSelector } from 'react-redux';
import store from './redux/Store';
import LoginPage from './auth/LoginPage';
import ErrorPage from './views/errorPage';
import Dashboard from './views/Dashboard';
import classroomlandingPage from './components/classroomlandingPage';
import Details from './components/details/assignmentdetailIndex';
import Layout from './layout/Layout';
import PublicPage from './components/PublicPage';
import ArchiveClasses from './components/Archive';
import ForgotPasswordForm from './components/forgetPassword/forgetPassword';
import OtpPage from './auth/OTP';
import PendingClasses from './components/PendingClasses';
import Loader from './components/Loader/Loader';
import { routesName } from './routes/routesName';
import { base_url_for_public_page } from "./redux/Constant";
import Sidebar from './components/LeftSidebar/index';
import StudentDashboard from "./views/StudentDashboard"
import { ThemeProvider } from '@mui/material/styles';
import theme from "./styles/theme/index"
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import SupportBubble from './views/supportTask';
import "react-toastify/dist/ReactToastify.css";


// import "./styles/globals.css"


const  App = () => {
  const currentUrl = window.location.href;
  const storepageUrl = base_url_for_public_page;
  const isLoading = useSelector((state) => state.ISLOADING.isLoading);
  const isSidebarOpen = useSelector((state) => state.TOGGLESIDEBAR.isSideBarOpen);
  const isTokenExpired = useSelector((state) => state.LOGINREDUCER.tokenExpired);
  const isStorePage = currentUrl.includes(storepageUrl);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1100px)");
  const routesPath = [
    { path: routesName.dashboardView, component: Dashboard },
    { path: `${routesName.classroomHome}/:courseName/:id`, component: classroomlandingPage },
    { path: `${routesName.assignmentDetails}/:courseName/:id/:SubComponentId`, component: Details },
    { path: routesName.publicpage, component: PublicPage },
    { path: routesName.forgetpassword, component: ForgotPasswordForm },
    { path: routesName.pendingClass, component: PendingClasses },
    { path: routesName.StudentDashboard, component: StudentDashboard }



  ];
  useEffect(() => {
    if (isTokenExpired && !toast.isActive("session-expired")) {
      toast.error("Session Expired, Please Login Again", {
        toastId: "session-expired"
      });
  
      // Add a delay before navigating
      const timer = setTimeout(() => {
        navigate(routesName.loginPage);
      }, 1500); // 2-second delay
  
      // Cleanup the timer if the component unmounts before the delay is over
      return () => clearTimeout(timer);
    }
  }, [isTokenExpired, navigate]);
  

  const AuthPath = [
    { path: routesName.loginPage, component: LoginPage },
    { path: routesName.otp, component: OtpPage },
  ];
        console.log = () => {};
        console.error = () => { }
        console.debug = () => { }
        console.warn = () => { }
  return (
    <>
          <Loader open={isLoading} />
          <Routes>
            {isStorePage ? (
              <>
                <Route index element={<PublicPage />} />
                {routesPath.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<Layout>{React.createElement(route.component)}</Layout>}
                  />
                ))}
              </>
            ) : (
              <>
                <Route index element={<LoginPage />} />
                {AuthPath.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
                <Route
                  path="*"
                  element={
                    <Box sx={{ display: 'flex', width: '99vw', overflowX: 'hidden' }}>
                      <Sidebar isOpen={isSidebarOpen} />
                      <Box
                        sx={{
                          flexGrow: 1,
                          marginLeft: isSidebarOpen && !isMobile ? '300px' : '0px',
                          transition: 'margin-left 0.4s ease',
                          overflowX: 'hidden', // Prevent horizontal overflow
                          width: '100%', // Ensure it doesn't exceed the viewport width
                        }}
                      >
                        <Routes>
                          {routesPath.map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              element={<Layout><route.component /></Layout>}
                            />
                          ))}

                        </Routes>
                        
                      </Box>
                    </Box>
                  }
                />
              </>
            )}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <SupportBubble />
          <ToastContainer/>

          </>
  );
}

export default App;
