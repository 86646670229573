import { GET_GROUP_MEMBERS  } from "../ActionType/Types";
import Constants from "../../Constant"

export function GetGroupMembersAction(GroupName,CourseId,onSuccess ,onFailure){
    return{
        type:GET_GROUP_MEMBERS,
        payload:{
            apiUrl:Constants.groupMembers,
            header:"application/json",
            metaData:true,
            requestType:'POST',
            reduxActionType:GET_GROUP_MEMBERS,
            onSuccess:onSuccess,
            onFailure:onFailure,
            body:{
                GroupName:GroupName,
                CourseId:CourseId
            }

        }
    }
}