import { GET_LEADERBOARD_RESULT} from "../ActionType/Types";
import Constants from "../../Constant"
export function GetLeaderBoardResultAction(SubcomponentIds,CourseLeaderboardId,onSuccess ,onFailure){
    return{
        type:GET_LEADERBOARD_RESULT,
        payload:{
            apiUrl:Constants.getLeaderBoardResult+"/"+CourseLeaderboardId,
            header:"application/json",
            metaData:true,
            requestType:'POST',
            reduxActionType:GET_LEADERBOARD_RESULT,
            onSuccess:onSuccess,
            onFailure:onFailure,
            body:{
                SubcomponentIds
            }
        }
    }
}